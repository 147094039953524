<script setup lang="ts">
import {
    HoverCard,
    HoverCardTrigger,
    HoverCardContent,
} from "@/Components/Shared/hoverCard";
import AppLoader from "@/Components/Shared/loader/AppLoader.vue";
import { apiPathUrl } from "@/Utils/Helpers";
import { ref } from "vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppAvatar from "@/Components/Shared/avatar/AppAvatar.vue";
import AppArticle from "@/Components/Shared/article/AppArticle.vue";

type Props = {
    userId: string;
};

const { userId } = defineProps<Props>();
const loading = ref(false);
const user = ref(null);

defineOptions({
    inheritAttrs: false,
});

const handleOpen = () => {
    if (user.value) {
        return;
    }

    loading.value = true;
    axios.get(apiPathUrl(`/users/get-by-id/${userId}`)).then(data => {
        user.value = data.data;
        loading.value = false;
    });
};
</script>

<template>
    <HoverCard
        :open-delay="500"
        @update:open="handleOpen"
    >
        <HoverCardTrigger as-child>
            <slot />
        </HoverCardTrigger>
        <HoverCardContent side="top">
            <AppLoader v-if="loading" />
            <div
                v-if="user && user.data"
                class="flex flex-col gap-3"
            >
                <div class="flex gap-3">
                    <AppAvatar
                        :src="user.data.avatar"
                        :alt="user.data.name"
                        size="xsmall"
                    />

                    <div class="flex flex-col gap-3">
                        <div class="flex flex-col gap-1">
                            <AppTitle size="xsmall">
                                @{{ user.data.username }}
                            </AppTitle>
                            <AppText
                                v-if="
                                    user.data.profile.first_name ||
                                    user.data.profile.last_name
                                "
                            >
                                {{ user.data.profile.first_name }}
                                {{ user.data.profile.last_name }}
                            </AppText>
                        </div>

                        <AppArticle
                            v-if="user.data.profile && user.data.profile.bio"
                        >
                            {{ user.data.profile.bio }}
                        </AppArticle>
                    </div>
                </div>

                <AppButtonGroup>
                    <AppLink
                        :href="user.data.path"
                        variant="buttonSmall"
                    >
                        Bekijk profiel
                    </AppLink>
                </AppButtonGroup>
            </div>
        </HoverCardContent>
    </HoverCard>
</template>
